import {getSubscribersMixins} from './getSubscribersMixins';

export const getUsersMixins = {
    mixins: [getSubscribersMixins],
    data() {
        return {
            numberOfUsers: '',
        };
    },

    methods: {
        async getCurrentPackage() {
            const currentPlanId = await this.getCurrentPlan();

            this.$useJwt.packages().then(res => {
                const {results} = res.data;

                let getCurrentPlan = results.find(
                    element => element.package.id == currentPlanId[0]
                );

                this.numberOfUsers = getCurrentPlan.number_of_allowed_users;
            });
        },
    },

    mounted() {
        //   this.getCurrentPackage();
    },
};
