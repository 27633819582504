<template>
     <span class="flex justify-content-between" @mouseenter="showIcon = true" @mouseleave="showIcon = false">
                   <span> ⦁ <span v-if="GET_LOCALE === 'sv'">{{ scope.swe_name }}</span><span v-else> {{
                       scope.eng_name
                     }}</span> </span>
                   <span v-if="showIcon">
                     <feather-icon
                         role="button"
                         class="text-red-600"
                         @click="performDelete"
                         size="12"
                         icon="Trash2Icon"
                     />
                   </span>
                 </span>
</template>

<script>
import {mapGetters} from "vuex"
import debounce from "lodash.debounce";

export default {
  name: "singleScope",
  props: {
    scope: {
      type: Object,
      required: true
    },
    role: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      showIcon: false
    }
  },
  methods: {
    performDelete() {
      this.$emit('onRemoveScope', {scope_id: this.scope.uuid, role_id: this.role.uuid})
    }
  },
  computed: {
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>