<template>
  <div>
    <div class="" :class="{'md-access-denied-overlay': isAccessDenied === true}" v-if="isAccessDenied === true"/>
    <div :class="{'access-denied-lock': isAccessDenied === true}" v-if="isAccessDenied === true" class="w-full">
      <access-denied :height="height" class="bg-white w-2/5 mx-auto"/>
    </div>
  </div>
</template>

<script>
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "mdAccessDenied",
  components: {
    accessDenied
  },
  props: {
    isAccessDenied: {
      type: Boolean,
      default: false

    },
    height: {
      type: Number,
      default: 200
    }
  }
}
</script>

<style scoped>

</style>