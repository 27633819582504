var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"rounded-lg"},[_c('b-card-text',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-row',{attrs:{"align-h":"end"}},[_c('transition',{attrs:{"name":"zoom-fade","mode":"out-in"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(
                                              _vm.currentComponent == 'UsersTable'
                                          ),expression:"\n                                              currentComponent == 'UsersTable'\n                                          "}],attrs:{"cols":"12","md":"6"}},[_c('i18n',{staticClass:"mb-0",attrs:{"path":_vm.locale.subtitle,"tag":"p","for":_vm.locale.anchor},scopedSlots:_vm._u([{key:"userNumber",fn:function(){return [_vm._v(" "+_vm._s(_vm.total_allow_users)+" ")]},proxy:true},{key:"link",fn:function(){return [_c('router-link',{attrs:{"to":{
                                                          name: 'configurationSettings',
                                                      }},domProps:{"textContent":_vm._s(
                                                          _vm.$t(_vm.locale.anchor)
                                                      )}})]},proxy:true}])})],1)],1),_c('b-col',{staticClass:"mt-2 mt-sm-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-center justify-content-md-end"},[_c('div',{staticClass:"bg-secondaryColor d-flex bigGap align-items-center tabWrapper"},_vm._l((_vm.$t(
                                                      _vm.locale.tabs
                                                  )),function(data,index){return _c('span',{key:index},[(data.active)?_c('b-button',{attrs:{"variant":"light"},domProps:{"textContent":_vm._s(data.label)}}):_c('p',{staticClass:"mb-0 text-light",attrs:{"role":"button"},domProps:{"textContent":_vm._s(data.label)},on:{"click":function($event){return _vm.switchTabs(data)}}})],1)}),0)])])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('KeepAlive',[_c('transition',{attrs:{"name":"zoom-fade","mode":"out-in"}},[_c(_vm.currentComponent,{tag:"component"})],1)],1)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }