<template>
  <div>
    <b-card class="rounded-lg">
      <b-card-text>
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col cols="12" class="mb-2">
                <b-row align-h="end">
                  <transition name="zoom-fade" mode="out-in">
                    <b-col
                        cols="12"
                        md="6"
                        v-show="
                                                currentComponent == 'UsersTable'
                                            "
                    >
                      <i18n
                          :path="locale.subtitle"
                          tag="p"
                          :for="locale.anchor"
                          class="mb-0"
                      >
                        <template #userNumber>

                          {{ total_allow_users }}
                        </template>
                        <template #link>
                          <router-link
                              :to="{
                                                            name: 'configurationSettings',
                                                        }"
                              v-text="
                                                            $t(locale.anchor)
                                                        "
                          />
                        </template>
                      </i18n>
                    </b-col>
                  </transition>

                  <b-col
                      cols="12"
                      md="6"
                      class="mt-2 mt-sm-0"
                  >
                    <div
                        class="d-flex justify-content-center justify-content-md-end"
                    >
                      <div
                          class="bg-secondaryColor d-flex bigGap align-items-center tabWrapper"
                      >
                                                <span
                                                    v-for="(data, index) in $t(
                                                        locale.tabs
                                                    )"
                                                    :key="index"
                                                >
                                                    <b-button
                                                        v-if="data.active"
                                                        variant="light"
                                                        v-text="data.label"
                                                    />

                                                    <p
                                                        v-else
                                                        @click="
                                                            switchTabs(data)
                                                        "
                                                        class="mb-0 text-light"
                                                        role="button"
                                                        v-text="data.label"
                                                    />
                                                </span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12">
                <KeepAlive>
                  <transition name="zoom-fade" mode="out-in">
                    <component :is="currentComponent"/>
                  </transition>
                </KeepAlive>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import UsersTable from '@/@core/components/user/usersTable.vue';
import RolePermission from '@/@core/components/user/rolePermission.vue';

export default {
  name: 'usersview',
  components: {UsersTable, RolePermission},

  data() {
    return {
      total_allow_users: '',
      currentComponent: 'UsersTable',
      locale: {
        subtitle: 'UsersCard.subtitle',
        anchor: 'UsersCard.anchor',
        tabs: 'UsersCard.tabs',
      },
    };
  },

  methods: {
    getUsers() {
      this.$useJwt.getUsers().then((res) => {
        const {allowed_users} = res.data;
        this.total_allow_users = allowed_users


      });
    },
    switchTabs(choosenTab) {
      let tabs = this.$t('UsersCard.tabs');
      tabs.find(tab => tab.active == true).active = false;

      choosenTab.active = true;

      this.currentComponent = choosenTab.component;
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    let activeTab = this.$t('UsersCard.tabs').find(
        tab => tab.active == true
    );
    this.getUsers()
    this.currentComponent = activeTab.component;
  },
};
</script>
