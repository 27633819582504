<template>
  <div :class="{'md-access-denied-height': accessDenied === true}">
    <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
    <div class="shadow leftCardBottomBorder">
      <div
          class="w-100 position-relative bg-secondaryColor p-2 text-white d-flex justify-content-between leftCardTopBorder"
      >
        <p
            class="mb-0 h2 font-weight-bold"
            style="color: inherit"
            v-text="$t(locale.currentUser)"
        />

        <b-button
            variant="white"
            @click="inviteUsers"
            class="text-secondaryColor position-relative"
            style="z-index: 1"
            v-text="$t(locale.inviteBtn)"
        />

        <!-- <img
                    src="@/assets/duplioAsset/icons/usersIcon.png"
                    width="200px"
                    class="position-absolute"
                    style="right: 0; bottom: -13px"
                /> -->
      </div>
    </div>

    <Loading v-if="loadingState"/>
    <b-table
        v-else
        ref="table"
        responsive
        :items="usersItem"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :sort-desc.sync="sortDesc"
        :sort-by.sync="sortBy"
        :empty-text="$t(locale.tableEmpty)"
        class="position-relative"
    >
      <template #head(checkbox) style="width: 20px">
        <div class="d-flex" style="margin-top: 1px">
          <b-form-checkbox
              v-model="selected"
              @change="checkAll"
              :value="checked"
              class="custom-control-primary my-auto"
          />
        </div>
      </template>
      <template #head(full_name)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>
      <template #head(email)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>
      <template #head(club)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>
      <template #head(role)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>
      <template #head(access)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>
      <template #head(actions)="data">
        <div class="d-flex">
          <span
              class="my-auto"
              style="margin-right: 5px"
              v-text="$t(data.field.label)"
          />

          <span class="d-flex flex-column position-relative">
            <feather-icon
                size="10"
                @click="sortingChanged(data, true)"
                class="cursor-pointer"
                icon="ChevronUpIcon"
                style="pointer: cursor"
                role="button"
            />

            <feather-icon
                @click="sortingChanged(data, false)"
                size="10"
                class="cursor-pointer"
                icon="ChevronDownIcon"
                role="button"
            />
          </span>
        </div>
      </template>

      <!-- Column: date -->
      <template #cell(checkbox)="data">
        <b-form-checkbox v-model="data.value"/>
      </template>

      <template #cell(full_name)="data">
        <div class="d-flex">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.image"/>
            </template>
          </b-media>

          <p class="font-weight-bold mb-0" v-text="data.value"/>
        </div>
      </template>

      <template #cell(email)="data">
        <div class="d-flex">
          <p class="font-weight-bold mb-0" v-text="data.value"/>
        </div>
      </template>

      <template #cell(club)="data">
        <div class="d-flex" v-if="data.value">
          {{ combineMessages(data.value) }}
          <!--          <p-->
          <!--              v-for="(dataClub, index) in data.value.slice(2)"-->
          <!--              :key="index"-->
          <!--              class="font-weight-bold mb-0"-->
          <!--          >-->
          <!--            <span class="mb-1" v-text="`${dataClub}`"/>-->
          <!--            ,-->
          <!--          </p>...-->
        </div>
      </template>

      <template #cell(role)="data">
        <div class="d-flex">
          <p
              class="font-weight-bold mb-0"
              v-text="data.value.role_definition"
          />
        </div>
      </template>

      <template #cell(actions)="data">
        <div class="d-flex">
          <b-button
              v-b-modal.modal-prevent-crud-users
              @click="editUser(data.item.uuid)"
              variant="flat-success"
              class="btn-icon rounded-circle"
          >
            <feather-icon icon="Edit3Icon"/>
          </b-button>

          <b-button
              v-b-modal.modal-prevent-remove-users
              @click="removeUser(data.item.uuid)"
              variant="flat-danger"
              class="btn-icon rounded-circle"
          >
            <feather-icon icon="Trash2Icon"/>
          </b-button>
        </div>
      </template>
    </b-table>

    <DeleteModal
        v-if="removeModal"
        :uuid="uuid"
        @refresh="getUsers"
        @closeModal="closeModal"
    />

    <Modal
        id="modalUtilsUser"
        :title="$t(locale.modalTitle)"
        :hide-footer="true"
        :options="{
        size: 'sm',
        'cancel-variant': 'outline-secondary',
        'no-close-on-backdrop': true,
      }"
    >
      <!-- :okEvent="validation" -->
      <b-card-text
          class="d-flex flex-column justify-content-center"
          style="gap: 1rem"
      >
        <div class="">
          <Loading v-if="loadingStateModal"/>
          <ValidationObserver v-show="showForm" ref="form">
            <div v-for="(form, index) in dataForm" :key="index">
              <ValidationProvider
                  v-if="form.validate && form.fieldName !== 'golfclub'"
                  :rules="form.rules"
                  :name="$t(form.label)"
                  #default="{ errors }"
              >
                <div class="d-flex mt-1 flex-column">
                  <label
                      for=""
                      class="h6 font-weight-bold"
                      v-if="form.fieldName !== 'multiGolfclub'"
                  >
                    {{ $t(form.label) }}
                    <span class="text-danger">*</span>
                  </label>

                  <b-form-input
                      v-if="form.type === 'input'"
                      v-model="form.value"
                      :placeholder="$t(form.label)"
                  />
                  <v-select
                      v-if="form.fieldName === 'role'"
                      :options="allRolesOptions"
                      label="label"
                      :clearable="false"
                      :placeholder="$t(form.label)"
                      v-model="form.value"
                      class="per-page-selector-send-method d-inline-block"
                  />

                  <!-- <v-select
                                        v-if="invite === false && form.fieldName == 'golfclub'"
                                        :options="allClubOptions"
                                        :clearable="false"
                                        :placeholder="$t(form.label)"
                                        v-model="form.value"
                                        class="per-page-selector-send-method d-inline-block"
                                    /> -->

                  <span
                      class="text-danger"
                      style="margin-top: 3px"
                      v-text="errors[0]"
                  />
                </div>
              </ValidationProvider>

              <ValidationProvider
                  v-if="
                  form.validate &&
                  form.fieldName === 'golfclub' &&
                  invite === false
                "
                  :rules="form.rules"
                  :name="$t(form.label)"
                  #default="{ errors }"
              >
                <div class="flex flex-col mt-1">
                  <label for="" class="h6 font-weight-bold">
                    {{ $t(form.label) }}
                    <span class="text-danger">*</span>
                  </label>
                  <div class="grid grid-cols-3 gap-3 mb-1">
                      <span
                          v-for="(item, i) in multiGolfClub"
                          :key="item.uuid"
                          class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                      >
                        <small>Golf #: {{ item.label }} </small>
                        <span
                            class="cursor-pointer"
                            @click="removeEditGolfAccount(index,i)"
                        >
                          <feather-icon icon="Trash2Icon"/>
                        </span>
                      </span>
                  </div>
                  <v-select
                      :options="allClubOptions"
                      :clearable="false"
                      :placeholder="$t(form.label)"
                      @option:selected="setEditInviteGolf"
                      v-model="goltAccountValue"
                      class="per-page-selector-send-method d-inline-block"
                  />
                  <!--                  <v-select-->
                  <!--                    v-if="invite === false && form.fieldName == 'golfclub'"-->
                  <!--                    :options="allClubOptions"-->
                  <!--                    :clearable="false"-->
                  <!--                    :placeholder="$t(form.label)"-->
                  <!--                    v-model="form.value"-->
                  <!--                    class="per-page-selector-send-method d-inline-block"-->
                  <!--                  />-->
                  <span
                      class="text-danger"
                      style="margin-top: 3px"
                      v-text="errors[0]"
                  />
                </div>
              </ValidationProvider>

              <div v-if="invite === true">
                <ValidationProvider
                    v-if="invite === true && form.fieldName === 'multiGolfclub'"
                    :name="$t(form.label)"
                    #default="{ errors }"
                >
                  <div class="flex flex-col mt-1">
                    <label for="" class="h6 font-weight-bold">
                      {{ $t(form.label) }}
                      <span class="text-danger">*</span>
                    </label>
                    <div class="grid grid-cols-3 gap-3 mb-1">
                      <span
                          v-for="(item, i) in multiGolfClub"
                          :key="item.uuid"
                          class="flex gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                      >
                        <small>Golf #: {{ item.label }}</small>
                        <span
                            class="cursor-pointer"
                            @click="removeGolfAccount(i)"
                        >
                          <feather-icon icon="Trash2Icon"/>
                        </span>
                      </span>
                    </div>
                    <v-select
                        :options="allClubOptions"
                        :clearable="false"
                        :placeholder="$t(form.label)"
                        @option:selected="setInviteGolf"
                        v-model="goltAccountValue"
                        class="per-page-selector-send-method d-inline-block"
                    />
                    <span
                        class="text-danger"
                        style="margin-top: 3px"
                        v-text="errors[0]"
                    />
                  </div>
                </ValidationProvider>
              </div>

              <div
                  class="mb-1 mt-1"
                  v-if="invite === false && form.type === 'checkbox'"
              >
                <span class="flex gap-3">
                  <span>{{ $t(form.label) }}</span>
                  <b-form-checkbox v-model="form.value"> </b-form-checkbox>
                </span>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modalUtilsUser')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="processing"
            @click="validation"
        >
          <span v-text="processing ? $t('Message.loading') :  $t('Message.proceed')"/>
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import UsersUtillsModal from "@/@core/components/user/UsersUtillsModal.vue";
import DeleteModal from "@/@core/components/user/DeleteModal.vue";
import {getUsersMixins} from "@/mixins/getUsersMixins";
import vSelect from "vue-select";
import useAppConfig from "@core/app-config/useAppConfig";
import {mapGetters} from "vuex";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";

export default {
  name: "usersComponent",
  mixins: [getUsersMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    UsersUtillsModal,
    DeleteModal,
    vSelect, mdAccessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      loadingStateModal: false,
      accessDenied: false,
      processing: false,
      showForm: true,
      selected: false,
      checked: "selected",
      invite: false,
      searchQuery: "",
      goltAccountValue: "",
      sortDesc: null,
      multiGolfClub: [],
      uuid: "",
      modalStatus: "",
      usersItem: [],
      allRolesOptions: [],
      allClubOptions: [],
      editModal: false,
      loadingState: true,
      sortBy: "",
      selectMode: "",
      openModal: false,
      selectedUser: {},
      removeModal: false,
      tableColumns: [],
      pagination: {
        currentPage: 1,
        totalUsers: 0,
        perPage: 20,
      },
      locale: {
        title: "UsersCard.title",
        subtitle: "UsersCard.subtitle",
        anchor: "UsersCard.anchor",
        removeTitle: "UsersCard.modal.removeTitle",
        removeSubtitle: "UsersCard.modal.removeSubtitle",
        currentUser: "UsersCard.currentUser",
        inviteBtn: "UsersCard.inviteBtn",
        yes: "UsersCard.yes",
        cancel: "UsersCard.cancel",
        modalTitle: "", // Important things don't remove this
      },
      dataForm: [
        {
          label: "UsersCard.modal.name",
          value: "",
          fieldName: "name",
          rules: "required",
          type: "input",
          validate: true,
          invite: true,
        },
        {
          label: "UsersCard.modal.email",
          value: "",
          fieldName: "email",
          rules: "required|email",
          type: "input",
          validate: true,
          invite: true,
        },
        {
          label: "UsersCard.modal.role",
          value: "",
          fieldName: "role",
          rules: "required",
          type: "select",
          validate: true,
          invite: true,
          options: [],
        },
        {
          label: "UsersCard.modal.club",
          value: "",
          fieldName: "golfclub",
          rules: "required",
          type: "select",
          validate: true,
          invite: false,
          options: [],
        },
        {
          label: "UsersCard.modal.club",
          value: "",
          fieldName: "multiGolfclub",
          rules: "",
          type: "select",
          validate: true,
          invite: true,
          options: [],
        },
        {
          label: "UsersCard.modal.clubAdmin",
          value: "",
          fieldName: "is_golfclub_admin_user",
          type: "checkbox",
          validate: false,
          invite: false,
        },
      ],
    };
  },

  computed: {
    currentUsers() {
      return this.$store.getters["appConfig/currentUser"];
    },
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },

  watch: {
    searchQuery(value) {
      this.$useJwt.getUsers({params: {search: value}}).then((res) => {
        const {results} = res.data;

        const {count} = res.data;

        this.pagination.totalUsers = count;

        this.renderErrorList(results);
      });
    },
    "pagination.currentPage": {
      handler(page) {
        this.$useJwt.getUsers({params: {page: page}}).then((res) => {
          const {results} = res.data;

          const {count} = res.data;

          this.pagination.totalUsers = count;

          this.renderErrorList(results);
        });
      },
    },
  },

  methods: {
    typeColor(type) {
      switch (type) {
        case "success":
          return "text-success";
        case "info":
          return "text-secondaryColor";
        case "error":
          return "text-danger";
      }
    },
    combineMessages(messages) {
      let message = ""
      if (messages) {
        if (messages.length > 2) {
          return messages.slice(0, 2).join(',') + '  ...'
        } else if (messages.length === 2) {
          return messages.join(',')
        } else if (messages.length === 1) {
          return messages.join(',')
        } else return message
      }
      return message
    },
    removeGolfAccount(index) {
      this.multiGolfClub.splice(index, 1);
    },
    removeEditGolfAccount(mainIndex, clubIndex) {
      this.multiGolfClub.splice(clubIndex, 1)
    },
    setInviteGolf(item) {
      const uuid = this.multiGolfClub.filter((el) => el.value === item.value);
      if (uuid.length < 1) {
        this.multiGolfClub.push(item);
      }
      this.goltAccountValue = "";
    },
    setEditInviteGolf(item) {
      const uuid = this.multiGolfClub.filter((el) => el.uuid === item.value);
      if (uuid.length < 1) {
        this.multiGolfClub.push(item);
      }
      this.goltAccountValue = "";
    },
    editUser(uuid) {
      this.$bvModal.show("modalUtilsUser");
      this.invite = false;
      this.loadingStateModal = true;
      this.showForm = false;

      this.$useJwt
          .getUsersByid(uuid)
          .then((res) => {
            const {data} = res.data;
            let getGolfClub = this.allClubOptions.find(
                (item) => item.label === data.golfclub.name
            );

            this.selectedUser = data;
            const role = {
              label: data.role ? data.role.role_definition : "",
              value: data.role ? data.role.uuid : "",
            };
            this.multiGolfClub = []
            data.club_accounts.map(item => {
              this.multiGolfClub.push({label: item.name, uuid: item.uuid})
            })
            this.dataForm = [
              {
                label: "UsersCard.modal.name",
                value: data.full_name,
                fieldName: "full_name",
                rules: "required",
                type: "input",
                validate: true,
                invite: true,
              },
              {
                label: "UsersCard.modal.email",
                value: data.email,
                fieldName: "email",
                rules: "required|email",
                type: "input",
                validate: true,
                invite: true,
              },
              {
                label: "UsersCard.modal.role",
                value: role,
                fieldName: "role",
                rules: "required",
                type: "select",
                validate: true,
                options: [],
                invite: true,
              },
              {
                label: "UsersCard.modal.club",
                value: getGolfClub,
                fieldName: "golfclub",
                rules: "",
                type: "select",
                validate: true,
                options: [],
                invite: false,
              },
              {
                label: "UsersCard.modal.club",
                value: getGolfClub,
                fieldName: "multiGolfclub",
                rules: "",
                type: "select",
                validate: false,
                options: [],
                invite: true,
              },
              {
                label: "UsersCard.modal.clubAdmin",
                value: data.is_golfclub_admin_user,
                fieldName: "is_golfclub_admin_user",
                type: "checkbox",
                validate: false,
                invite: false,
              },
            ];
          })
          .finally(() => {
            this.loadingStateModal = false;
            this.showForm = true;
          });

      this.modalStatus = "edit";
      this.locale.modalTitle = "UsersCard.modal.editTitle";
    },

    removeUser(uuid) {
      this.removeModal = true;
      this.uuid = uuid;
    },

    inviteUsers() {
      this.invite = true;
      this.dataForm = this.dataForm.map((el) => {
        return {
          ...el,
          value: "",
        };
      });
      this.modalStatus = "invite";
      this.multiGolfClub = []
      this.locale.modalTitle = "UsersCard.modal.inviteTitle";
      this.$bvModal.show("modalUtilsUser");
    },
    onRowSelected(value) {
      this.openModal = true;
    },
    clearSelected() {
      this.$refs.table.clearSelected();
    },

    renderTableColoumn() {
      let tablePrototype = [
        {
          key: "checkbox",
          tdClass: "bTableMainThStyle",
          thStyle: {width: "20px", padding: "0.72rem 10px"},
        },
        {
          key: "full_name",
          label: "UsersCard.modal.name",
          tdClass: "bTableTdDateStyle",
          thStyle: {padding: "0.72rem 0"},
        },
        {
          key: "email",
          label: "UsersCard.modal.email",
        },
        {
          key: "club",
          label: "UsersCard.modal.club",
        },
        {
          key: "role",
          label: "UsersCard.modal.role",
        },
        {
          key: "actions",
          label: "UsersCard.modal.actions",
        },
      ];

      // if (this.currentUsers.role == 'Owner')
      //     tablePrototype.push({
      //         key: 'actions',
      //         label: 'UsersCard.modal.actions',
      //     });

      this.tableColumns = tablePrototype;
    },

    renderUsers(value) {
      this.loadingState = true;

      this.renderTableColoumn();

      this.usersItem = value.map((element) => {
        return {
          ...element,
          uuid: element.uuid,
          checkbox: false,
          club: element.club_accounts.map((club) => club.name),
        };
      });

      this.loadingState = false;
    },
    checkAll(e) {
      this.usersItem.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
    },
    getUsers() {
      this.accessDenied = false
      this.$useJwt.getUsers().then((res) => {
        const {data} = res.data;
        const {count} = res.data;

        this.pagination.totalUsers = count;

        this.renderUsers(data);
      }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      });
    },

    sortingChanged(sortBy, desc) {
      this.sortBy = sortBy.column;

      this.sortDesc = desc;
    },

    closeModal() {
      this.openModal = false;
      this.removeModal = false;
      this.uuid = "";
    },

    validation(event) {
      event.preventDefault();

      this.$refs.form.validate().then((res) => {
        if (res) {
          this.modalStatus == "edit"
              ? this.UpdateOrCreateUserForm("edit")
              : this.UpdateOrCreateUserForm("invite");
        }
      });
    },
    UpdateOrCreateUserForm(key) {
      if (key === "invite" && this.multiGolfClub.length < 1) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Add golf account to invite user",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }

      this.processing = true;
      const payload = {};
      this.dataForm.map((el) => {
        if (el.fieldName !== "golfclub" && el.fieldName !== "multiGolfclub") {
          if (el.fieldName === "role") {
            payload["role_uuid"] = el.value.value;
          } else if (el.fieldName === "full_name") {
            payload["name"] = el.value;
          } else {
            payload[el.fieldName] = el.value;
          }
        }
      });

      if (key === "invite") {
        payload["club_account_uuids"] = this.multiGolfClub.map(
            (el) => el.value
        );
      } else {
        payload["club_account_uuids"] = this.multiGolfClub.map(
            (el) => el.uuid
        );
      }

      const mode =
          key === "invite"
              ? {URL: "/invite", method: "post"}
              : {URL: `/${this.selectedUser.uuid}`, method: "put"};
      this.$useJwt
          .customUser(mode, payload)
          .then((res) => {
            this.getUsers();
            this.processing = false;
            this.multiGolfClub = [];
            this.$bvModal.hide("modalUtilsUser");
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.processing = false;
            this.popupMsg(
                this.$t('Message.Failed'),
                err.response.data[this.locale_message],
                'AlertTriangleIcon',
                'danger'
            );
          });
    },
  },

  mounted() {
    this.getUsers();

    this.$useJwt.getRole().then((res) => {
      this.allRolesOptions = res.data.data.results.map((el) => {
        return {label: el.role_definition, value: el.uuid};
      });
    });

    this.$useJwt.getMyAccount().then((res) => {
      const {role} = res.data.data;
      const {club_accounts} = res.data.data;

      this.allClubOptions = club_accounts.map((item) => {
        return {
          label: item.name,
          value: item.uuid,
        };
      });

      // if (role == 'Owner') {
      //     this.dataForm[2].options = this.$t('UsersCard.ownerRoles');
      // } else this.dataForm[2].options = this.$t('UsersCard.roles');
    });
  },
};
</script>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}

.leftCardTopBorder {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important;
}

.leftCardBottomBorder {
  border-bottom-left-radius: 0.6rem !important;
  border-bottom-right-radius: 0.6rem !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
