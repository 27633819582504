<template>
  <div class="mt-3" :class="{'md-access-denied-height': accessDenied === true}">
    <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
    <b-row>
      <b-col cols="12" md="6" class="sticky-table">
        <div class="">
          <p class="mb-0 h2" v-text="'Behörigheter'"/>
        </div>

        <div class="d-flex flex-column mt-3 bigGap">
          <div
              v-for="(colap, index) in scoped"
              :key="index"
              role="button"
              :class="colap.visible ? null : 'collapsed'"
              :aria-controls="`collapse-${index}`"
              @click="colap.visible = !colap.visible"
          >
            <div class="d-flex smallGap align-items-center">
              <p class="mb-0 h4" v-text="colap[getCategory]"/>

              <feather-icon
                  size="20"
                  :icon="colap.visible ? 'MinusIcon' : 'PlusIcon'"
              />
            </div>
            <div class="mt-2" v-if="colap.visible === true">
              <Container
                  behaviour="copy"
                  group-name="main"
                  :get-child-payload="
                  (payload) => getChildPayload(payload, index)
                "
              >
                <Draggable
                    class="ml-2 draggable-item"
                    v-for="item in colap.scopes"
                    :key="item.uuid"
                >
                  <li class="px-1 rounded collapsibleItem">
                    {{ item[getName] }}
                  </li>
                </Draggable>
              </Container>
            </div>

            <!--            <b-collapse class="mt-2" v-model="colap.visible">-->
            <!--              -->
            <!--            </b-collapse>-->
          </div>
        </div>
      </b-col>

      <b-col cols="12" md="6">
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0 h2" v-text="$t(locale.title)"/>

          <b-button
              v-b-modal.addNewPermision
              variant="outline-secondaryColor"
              size="sm"
          >
            {{ $t(locale.addButton) }}
            <feather-icon size="16" icon="PlusIcon"/>
          </b-button>
        </div>

        <div class="d-flex flex-column mt-3 bigGap">
          <div v-for="(role, index) in rolesList" :key="index">
            <div class="d-flex align-items-center smallGap">
              <div class="flex justify-content-between w-full">
                <div class="flex">
                  <p class="mb-0 h4 mr-1" v-text="role.role_definition"/>
                  <feather-icon
                      v-if="role.uuid"
                      role="button"
                      @click="performDelete(role)"
                      size="16"
                      icon="Trash2Icon"
                  />
                </div>
                <div>
                  <feather-icon
                      size="20"
                      :icon="role.visible ? 'MinusIcon' : 'PlusIcon'"
                      @click="onOpenRole(role)"
                  />
                </div>
              </div>
            </div>

            <hr/>
            <div v-if="role.visible === true">
              <Container
                  :drop-placeholder="true"
                  :orientation="'vertical'"
                  group-name="main"
                  @drop="(payload) => onDrop(payload, index)"
              >
                <!--              <Draggable v-for="(rule, i) in role.scopes" :key="i" class="ml-2" style="padding-top: 5px">-->
                <!--                <single-scope :scope="rule" :role="role" :key="i"/>-->
                <!--              </Draggable>-->
                <ul class="ml-2" style="padding-top: 5px">
                  <li v-for="(rule, i) in role.scopes" :key="i">
                    <single-scope :scope="rule" :role="role" :key="i" @onRemoveScope="deleteScope"/>
                  </li>
                </ul>
              </Container>
            </div>

          </div>

          <!-- <div class="d-flex mt-4 justify-content-end">
                        <b-button variant="primary" v-text="$t(locale.save)" />
                    </div> -->
        </div>
      </b-col>
    </b-row>

    <Modal
        :okEvent="addNewRole"
        :closeEvent="closeModal"
        :options="{ 'no-close-on-backdrop': true, 'no-close-on-esc': true }"
        id="addNewPermision"
        :title="$t(locale.newApps)"
    >
      <ValidationObserver ref="form">
        <ValidationProvider
            rules="required"
            #default="{ errors }"
            :name="$t(locale.name)"
        >
          <div class="d-flex mt-1 flex-column">
            <label for="" class="h6 font-weight-bold">
              {{ $t(locale.name) }}
              <span class="text-danger">*</span>
            </label>
            <b-form-input v-model="roleName" :placeholder="$t(locale.name)"/>

            <span
                class="text-danger"
                style="margin-top: 3px"
                v-text="errors[0]"
            />
          </div>
        </ValidationProvider>
      </ValidationObserver>
    </Modal>

    <CustomNoHeaderModalVue
        v-if="openRemoveRole"
        width="w-11/12 md:w-1/4"
        :title="''"
        @handleClose="openRemoveRole = false"
        :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
            class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          {{ $t(locale.removeTitle) }}
        </h2>
      </div>
      <div class="flex flex-col p-2" slot="body">
        <b-card-text
            class="d-flex flex-column text-center align-items-center justify-content-center"
            style="gap: 1rem"
        >
          <img
              src="@/assets/duplioAsset/icons/warningCircle.png"
              width="70px"
              height="70px"
          />
          <p class="mb-0 pb-0">
            {{
              $t(locale.removeRoleTitle, {
                role,
              })
            }}
          </p>
        </b-card-text>
        <div class="flex justify-end">
          <button
              :disabled="processing"
              @click="proceedRoleRemoval"
              class="flex p-1 w-full text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md"
          >
            <span class="flex justify-center w-full" v-if="processing">
              <b-spinner small type="grow"/>
              {{ $t("Message.loading") }}...
            </span>

            <span
                v-else
                class="whitespace-nowrap text-center w-full text-base"
                v-text="$t(locale.removeTitle)"
            />
          </button>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import {Container, Draggable} from "vue-smooth-dnd";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import {mapGetters} from "vuex"
import singleScope from "@core/components/user/singleScope";

import mdAccessDenied from "@core/components/permission/mdAccessDenied";

export default {
  name: "rolePermission",
  components: {
    ValidationProvider,
    ValidationObserver,
    Container,
    Draggable,
    CustomNoHeaderModalVue,
    singleScope,
    mdAccessDenied,
  },
  data() {
    return {
      scoped: [],
      roleName: "",
      openRemoveRole: false,
      processing: false,
      accessDenied: false,
      visible: true,
      config: useAppConfig(),
      // rolesList: this.$t('UsersCard.rolePermission.rolesList'),
      ruleList: ["here"],
      rules: this.$t("UsersCard.rolePermission.rules"),
      locale: {
        title: "UsersCard.rolePermission.title",
        title2: "UsersCard.rolePermission.title2",
        addButton: "UsersCard.rolePermission.addButton",
        save: "UsersCard.rolePermission.save",
        collapse: "UsersCard.rolePermission.collapse",
        newApps: "UsersCard.rolePermission.newApps",
        name: "UsersCard.modal.name",
        removeRoleTitle: "UsersCard.modal.removeRoleTitle",
        removeTitle: "UsersCard.modal.removeHeaderTitle",
      },
      removeData: {},
      role: "",
      rolesList: [],
      copyRowList: [
        {
          role_definition: "Owner",
          uuid: "",
          scopes: [],
        },
        {
          role_definition: "Manager",
          uuid: "",
          scopes: [],
        },
        {
          role_definition: "Accountant",
          uuid: "",
          scopes: [],
        },
      ],
    };
  },
  computed: {
    getCategory() {
      if (this.getLang == "") {
        return this.swedishLang ? "swe_category" : "eng_category";
      } else return this.getLang == "sv" ? "swe_category" : "eng_category";
    },
    getName() {
      if (this.getLang == "") {
        return this.swedishLang ? "swe_name" : "eng_name";
      } else return this.getLang == "sv" ? "swe_name" : "eng_name";
    },
    getLang() {
      return this.config.lang.value;
    },
    message() {
       return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },

  watch: {
    getLang() {
      // this.rolesList = this.$t('UsersCard.rolePermission.rolesList');
      // this.rules = this.$t('UsersCard.rolePermission.rules');
    },
  },
  mounted() {
    this.getPermissionScope();
    this.getPermissionRole();
    window.Bus.$on('remove-scope-from-role', e => {
      this.deleteScope(e.role_id, e.scope_id)
    })
  },
  methods: {
    onOpenRole: function (role) {
      role.visible = !role.visible
    },
    deleteScope(payload) {
      let role_id = payload.role_id
      let scope_id = payload.scope_id
      let role = this.rolesList.filter(item => item.uuid === role_id)

      if (role.length > 0) {
        let excludedScope = role[0].scopes.filter(item => String(item.uuid) !== String(scope_id))
        let roleIndex = this.rolesList.findIndex(item => item.uuid === role_id)
        let scope_ids = []
        excludedScope.map(item => {
          scope_ids.push(item.uuid)
        })
        const payload = {
          role_definition: role[0]['role_definition'],
          scope_uuids: scope_ids,
        };
        const mode = {URL: `roles/${role_id}`, method: "put"};
        this.$useJwt
            .createNewRole(mode, payload)
            .then((res) => {
              this.rolesList[roleIndex].scopes = excludedScope
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.locale_message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.locale_message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }

    },
    addNewRole($event) {
      //   $event.preventDefault();
      this.$useJwt.createNewRole({method: "post", URL: 'roles'}, {'role_definition': this.roleName}).then(res => {
        this.popupMsg(
            this.$t("Message.Success"),
            res.data[this.locale_message],
            "CheckIcon",
            "success"
        );
        this.getPermissionRole()
      }).catch(err => {
        this.popupMsg(
            this.$t("Message.Failed"),
            err.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    },
    getPermissionScope() {
      this.$useJwt.getScopeCategories().then((res) => {
        res.data.data.map(item => {
          item.visible = false
        })
        this.scoped = res.data.data
        // res.data.data.results.forEach((element) => {
        //   const index = groupData.findIndex(
        //       (el) => el.category.uuid === element.category.uuid
        //   );
        //   if (index !== -1) {
        //     groupData[index].scoped.push({
        //       uuid: element.uuid,
        //       eng_name: element.eng_name,
        //       swe_name: element.swe_name,
        //     });
        //   } else {
        //     groupData.push({
        //       visible: true,
        //       category: {
        //         eng_category: element.category.eng_category,
        //         swe_category: element.category.swe_category,
        //         uuid: element.category.uuid,
        //       },
        //       scoped: [],
        //     });
        //   }
        // });
        //
        // this.scoped = groupData;
      });
    },
    getPermissionRole() {
      this.$useJwt
          .getRole()
          .then((res) => {
            this.rolesList = [];
            if (res.data.data.results.length > 0) {
              res.data.data.results.forEach((element) => {
                const obj = {};
                obj.role_definition = element.role_definition;
                obj.visible = false;
                obj.uuid = element.uuid;
                obj.scopes = [...element.scopes];

                // const index = groupData.findIndex(el => el.role_definition === element.role_definition)
                // if (index !== -1) {
                //     groupData[index].uuid = element.uuid
                //     // const scopeIndex = groupData[index].scopes.findIndex(scopeEl => scopeEl.uuid === )
                //     groupData[index].scopes = [...element.scopes]
                // }
                this.rolesList.push(obj);
              });
              // this.$nextTick(() => {
              //     this.rolesList = [...groupData]
              // })
            } else {
              // const groupData = JSON.parse(JSON.stringify(this.copyRowList))
              // this.$nextTick(() => {
              //     this.rolesList = [...groupData]
              // })
            }
          })
          .catch((err) => {
            this.accessDenied = false
            if (err.response.status === 403) {
              this.accessDenied = true
            } else {
              this.accessDenied = false
            }
          });
    },
    onDrop(dropResult, parentIndex) {
      const {payload, addedIndex} = dropResult;
      if (addedIndex !== null) {
        // Run check for duplicate
        const filter = this.rolesList[parentIndex].scopes.filter(
            (el) => el.uuid === payload.uuid
        );
        if (filter.length < 1) {
          this.updateRoleAfterDrop(
              this.rolesList[parentIndex].role_definition,
              payload,
              parentIndex
          );
        }
      }
      // this.rolesList[parentIndex].role = this.applyDrag(this.rolesList[parentIndex].role, dropResult, parentIndex)
    },
    applyDrag(arr, dragResult, parentIndex) {
      const {removedIndex, addedIndex, payload} = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      return result;
    },
    updateRoleAfterDrop(roleName, targetScope, parentIndex) {
      try {
        let roles = this.rolesList[parentIndex]
        let existingScopes = []
        if (roles.hasOwnProperty('scopes') && roles.scopes.length > 0) {
          roles.scopes.map(item => {
            existingScopes.push(item.uuid)
          })
        }
        if (existingScopes.includes(targetScope.uuid) === true) {
          return false
        }
        existingScopes.push(targetScope.uuid)
        const mode = {URL: "", method: ""};
        // Run check to either update or create new definition
        const existParentUuid = this.rolesList[parentIndex].uuid;
        const payload = {
          role_definition: roleName,
          scope_uuids: existingScopes,
        };
        if (existParentUuid) {
          // Update definition
          mode.URL = `roles/${existParentUuid}`;
          mode.method = "put";
        } else {
          // create new
          mode.URL = `roles`;
          mode.method = "post";
        }

        this.$useJwt
            .createNewRole(mode, payload)
            .then((res) => {
              this.rolesList[parentIndex].scopes.push(targetScope)
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.locale_message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.locale_message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      } catch (e) {
      }

    },
    createRole(parent, uuid, parentIndex) {
      let roles = this.rolesList[parentIndex]
      let existingScopes = []
      if (roles.hasOwnProperty('scopes') && roles.scopes.length > 0) {
        roles.scopes.map(item => {
          existingScopes.push(item.uuid)
        })
      }
      if (existingScopes.includes(uuid) === true) {
        return false
      }
      existingScopes.push(uuid)
      const mode = {URL: "", method: ""};
      // Run check to either update or create new definition
      const existParentUuid = this.rolesList[parentIndex].uuid;
      const payload = {
        role_definition: parent,
        scope_uuids: existingScopes,
      };
      if (existParentUuid) {
        // Update definition
        mode.URL = `roles/${existParentUuid}`;
        mode.method = "put";
      } else {
        // create new
        mode.URL = `roles`;
        mode.method = "post";
      }

      this.$useJwt
          .createNewRole(mode, payload)
          .then((res) => {
            this.getPermissionRole();

            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.locale_message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    getChildPayload(childIndex, parentIndex) {
      return this.scoped[parentIndex].scopes[childIndex];
    },
    proceedRoleRemoval() {
      this.processing = true;
      const mode = {URL: `roles/${this.removeData.uuid}`, method: "delete"};
      this.$useJwt
          .createNewRole(mode)
          .then((res) => {
            this.getPermissionRole();
            this.processing = false;
            this.openRemoveRole = false;

            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.processing = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    performDelete(item) {
      if (item.uuid) {
        this.$nextTick(() => {
          this.role = item.role_definition;
          this.removeData = item;
          this.openRemoveRole = true;
        });
      }
    },
    closeModal() {
    },
  },
};
</script>

<style scoped>
.collapsibleItem {
  width: 250px;
  border: 2px solid #6357ff;
  margin-top: 5px;
}

.sticky-table {
  position: -webkit-sticky;
  position: sticky;
  top: 61px;
  background-color: #fff;
  /* height: 50px; */
  padding: 7px;
}
</style>
